<template>
<body>
    <div id="__next">
        <div>
            <div>
                <div class="w-100" style="height: 60px"></div>
                <Header />
            </div>
            <div class="hpgrid-max-width mt-auto mr-auto mb-md ml-auto center">
                <div class="mr-sm ml-sm mr-0-ns ml-0-ns pt-md">
                   <img src="../assets/images/News-Banner.png" class="img-responsive w-100" />
                </div>
            </div>
            <div class="b bh mt-lg pt-lg">
                <main id="main-content" class="flex-grid flex flex-wrap mr-auto ml-auto print-mt-none">
                    <div class="search-app-container mr-auto ml-auto flex flex-column col-8-lg">
                        <section class="flex search-wrapper">
                            <div class="w-100">
                                <div class="aa-Autocomplete" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="autocomplete-0-label">
                                    <form class="aa-Form" action="" novalidate="" role="search">
                                        <div class="aa-InputWrapperPrefix"><label class="aa-Label" for="autocomplete-0-input" id="autocomplete-0-label"><button class="aa-SubmitButton" type="submit" title="Submit"><svg class="aa-SubmitIcon" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                                                        <path d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z"></path>
                                                    </svg></button></label>
                                            <div class="aa-LoadingIndicator" hidden=""><svg class="aa-LoadingIcon" viewBox="0 0 100 100" width="20" height="20">
                                                    <circle cx="50" cy="50" fill="none" r="35" stroke="currentColor" stroke-dasharray="164.93361431346415 56.97787143782138" stroke-width="6">
                                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;90 50 50;180 50 50;360 50 50" keyTimes="0;0.40;0.65;1"></animateTransform>
                                                    </circle>
                                                </svg></div>
                                        </div>
                                        <div class="aa-InputWrapper"><input class="aa-Input" aria-autocomplete="both" aria-labelledby="autocomplete-0-label" id="autocomplete-0-input" autocomplete="off" autocorrect="off" autocapitalize="off" enterkeyhint="search" spellcheck="false" placeholder="" maxlength="512" type="search"></div>
                                        <div class="aa-InputWrapperSuffix"><button class="aa-ClearButton" type="reset" title="Clear"><svg class="aa-ClearIcon" viewBox="0 0 24 24" width="18" height="18" fill="currentColor">
                                                    <path d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                                </svg></button></div>
                                    </form>
                                </div>
                            </div><button class="pa-0 flex-shrink-0 brad-50 border-box transition-colors duration-200 ease-in-out bg-offblack hover-bg-gray-darker focus-bg-gray-darker white b-solid bw bc-transparent focus-bc-black search-submit-btn self-center ml-xs focus-highlight h-md w-md pointer" aria-label="Search">
                                <div class="flex items-center justify-center h-100 w-100" data-sc-v="6.5.3" data-sc-c="buttonicon"><svg class="content-box fill-current" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="img" data-sc-v="6.5.3" data-sc-c="iconbase">
                                        <title data-sc-v="6.5.3" data-sc-c="iconbase">Search</title>
                                        <path d="M10.974 9.56l3.585 3.585-1.414 1.414-3.585-3.585a5.466 5.466 0 1 1 1.414-1.414zm-1.04-3.094a3.466 3.466 0 1 0-6.934 0 3.466 3.466 0 0 0 6.933 0z" fill-rule="nonzero" data-sc-v="6.5.3" data-sc-c="iconbase"></path>
                                    </svg></div>
                            </button>
                        </section>
                        <section class="search-facets-wrapper">
                            <div class="facet-dropdown-container"><button class="inline-flex items-center justify-center lh-md overflow-hidden border-box min-w-btn transition-colors duration-200 ease-in-out font-sans-serif font-bold antialiased    facet-dropdown focus-highlight brad-lg pl-md pr-md h-md pt-0 pb-0 pointer">
                                    <div class="flex justify-center h-100 items-center flex-row-reverse" data-sc-v="6.5.3" data-sc-c="buttonchildren">
                                        <div class="flex items-center ml-xs" data-sc-v="6.5.3" data-sc-c="buttonchildren"><svg class="content-box fill-current" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="img" data-sc-v="6.5.3" data-sc-c="iconbase">
                                                <title data-sc-v="6.5.3" data-sc-c="iconbase">ChevronDown</title>
                                                <path d="M8 9.293L13.293 4l1.414 1.414-6 6a1 1 0 0 1-1.414 0l-6-6L2.707 4 8 9.293z" fill-rule="nonzero" data-sc-v="6.5.3" data-sc-c="iconbase"></path>
                                            </svg></div>
                                        <div class="center" data-sc-v="6.5.3" data-sc-c="buttonchildren"><span aria-label="Including results from Any Time">Any Time</span></div>
                                    </div>
                                </button></div>
                            <div class="facet-dropdown-container"><button class="inline-flex items-center justify-center lh-md overflow-hidden border-box min-w-btn transition-colors duration-200 ease-in-out font-sans-serif font-bold antialiased    facet-dropdown focus-highlight brad-lg pl-md pr-md h-md pt-0 pb-0 pointer">
                                    <div class="flex justify-center h-100 items-center flex-row-reverse" data-sc-v="6.5.3" data-sc-c="buttonchildren">
                                        <div class="flex items-center ml-xs" data-sc-v="6.5.3" data-sc-c="buttonchildren"><svg class="content-box fill-current" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="img" data-sc-v="6.5.3" data-sc-c="iconbase">
                                                <title data-sc-v="6.5.3" data-sc-c="iconbase">ChevronDown</title>
                                                <path d="M8 9.293L13.293 4l1.414 1.414-6 6a1 1 0 0 1-1.414 0l-6-6L2.707 4 8 9.293z" fill-rule="nonzero" data-sc-v="6.5.3" data-sc-c="iconbase"></path>
                                            </svg></div>
                                        <div class="center" data-sc-v="6.5.3" data-sc-c="buttonchildren"><span aria-label="Including results from 38 sections">All Sections (
                                                <!-- -->38
                                                <!-- -->)</span></div>
                                    </div>
                                </button></div>
                            <div class="facet-dropdown-container"><button class="inline-flex items-center justify-center lh-md overflow-hidden border-box min-w-btn transition-colors duration-200 ease-in-out font-sans-serif font-bold antialiased    facet-dropdown focus-highlight brad-lg pl-md pr-md h-md pt-0 pb-0 pointer">
                                    <div class="flex justify-center h-100 items-center flex-row-reverse" data-sc-v="6.5.3" data-sc-c="buttonchildren">
                                        <div class="flex items-center ml-xs" data-sc-v="6.5.3" data-sc-c="buttonchildren"><svg class="content-box fill-current" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="img" data-sc-v="6.5.3" data-sc-c="iconbase">
                                                <title data-sc-v="6.5.3" data-sc-c="iconbase">ChevronDown</title>
                                                <path d="M8 9.293L13.293 4l1.414 1.414-6 6a1 1 0 0 1-1.414 0l-6-6L2.707 4 8 9.293z" fill-rule="nonzero" data-sc-v="6.5.3" data-sc-c="iconbase"></path>
                                            </svg></div>
                                        <div class="center" data-sc-v="6.5.3" data-sc-c="buttonchildren"><span aria-label="Including results by 100 authors">All Authors (
                                                <!-- -->100
                                                <!-- -->)</span></div>
                                    </div>
                                </button></div>
                            <div class="facet-dropdown-container"><button class="inline-flex items-center justify-center lh-md overflow-hidden border-box min-w-btn transition-colors duration-200 ease-in-out font-sans-serif font-bold antialiased    facet-dropdown focus-highlight brad-lg pl-md pr-md h-md pt-0 pb-0 pointer">
                                    <div class="flex justify-center h-100 items-center flex-row-reverse" data-sc-v="6.5.3" data-sc-c="buttonchildren">
                                        <div class="flex items-center ml-xs" data-sc-v="6.5.3" data-sc-c="buttonchildren"><svg class="content-box fill-current" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="img" data-sc-v="6.5.3" data-sc-c="iconbase">
                                                <title data-sc-v="6.5.3" data-sc-c="iconbase">ChevronDown</title>
                                                <path d="M8 9.293L13.293 4l1.414 1.414-6 6a1 1 0 0 1-1.414 0l-6-6L2.707 4 8 9.293z" fill-rule="nonzero" data-sc-v="6.5.3" data-sc-c="iconbase"></path>
                                            </svg></div>
                                        <div class="center" data-sc-v="6.5.3" data-sc-c="buttonchildren"><span>Sort by
                                                <!-- -->Relevancy</span></div>
                                    </div>
                                </button></div><button class="inline-flex items-center justify-center lh-md overflow-hidden border-box min-w-btn transition-colors duration-200 ease-in-out font-sans-serif font-bold antialiased bg-gray-lighter gray  reset-btn focus-highlight brad-lg pl-md pr-md h-md pt-0 pb-0 not-allowed" disabled="" aria-label="Reset filters">
                                <div class="flex justify-center h-100 items-center flex-row-reverse" data-sc-v="6.5.3" data-sc-c="buttonchildren">
                                    <div class="flex items-center ml-xs" data-sc-v="6.5.3" data-sc-c="buttonchildren"><svg class="content-box fill-current" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="img" data-sc-v="6.5.3" data-sc-c="iconbase">
                                            <title data-sc-v="6.5.3" data-sc-c="iconbase">Refresh</title>
                                            <path d="M7.872 2a6 6 0 1 1-3.85 10.603l1.284-1.534a4 4 0 1 0-.199-5.961L7 7H2V2l1.693 1.693A5.99 5.99 0 0 1 7.872 2z" fill-rule="nonzero" data-sc-v="6.5.3" data-sc-c="iconbase"></path>
                                        </svg></div>
                                    <div class="center" data-sc-v="6.5.3" data-sc-c="buttonchildren">Reset</div>
                                </div>
                            </button>
                        </section>
                        <div class="flex font--meta-text font-sm items-center" style="min-height:41px">
                            <div class="flex font--meta-text font-xxs flex-wrap w-100">
                                <div class="bold mr-xxs no-wrap">4,406</div>results related to<div class="bold ml-xxs">"dd"</div>
                            </div>
                        </div>
                        <section class="search-results-wrapper">

                            <article class="single-result mt-sm pb-sm">
                                <div class="content-lg pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light">
                                    <div class="pr-sm flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-meta-text font-xxxxs bold mb-xs">Arts &amp; Entertainment</div><a href="https://www.washingtonpost.com/entertainment/music/ddm-michael-k-williams-ballad-of-omar/2021/09/22/7a5cb9c0-1a1a-11ec-bcb8-0cb135811007_story.html" class="font-md font--magazine-headline font-bold mb-sm black">How Michael K. Williams helped Baltimore rapper DDM find his voice</a>
                                            <div class="font-body font-light font-xxs antialiased gray-dark lh-1 mt-sm mb-sm">Last year’s ‘The Ballad of Omar’ project takes on new resonance in the wake of the actor’s recent death.</div>
                                        </div>
                                        <div class="flex items-center">
                                            <div class="bold mr-md" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Lawrence Burney</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">September 23, 2021 at 11:54 PM GMT+5:30</span>
                                        </div>
                                    </div>
                                    <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/entertainment/music/ddm-michael-k-williams-ballad-of-omar/2021/09/22/7a5cb9c0-1a1a-11ec-bcb8-0cb135811007_story.html" class="result-image-link">
                                            <figure data-qa="sc-figure-image" class="relative result-image-lg" data-sc-v="6.5.3" data-sc-c="image">
                                                <div data-qa="sc-skeleton-image" class="font-0" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 240px; height: 180px; opacity: 1; transition: opacity 400ms ease-in-out 0s;"><img data-qa="sc-image" alt="How Michael K. Williams helped Baltimore rapper DDM find his voice" class="font-0" width="240" height="180" loading="lazy" src="https://www.washingtonpost.com/wp-apps/imrs.php?src=https%3A%2F%2Farc-anglerfish-washpost-prod-washpost.s3.amazonaws.com%2Fpublic%2FAG2J5FA3XAI6ZA4AL65NXRB67A.jpg&amp;w=240&amp;h=180" data-sc-v="6.5.3" data-sc-c="fadeinimage"></div>
                                            </figure>
                                        </a></div>
                                </div>
                                <div class="content-sm flex flex-column">
                                    <div class="pa-xxxs pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light ">
                                        <div class="pr-xs flex flex-column justify-between w-100">
                                            <div>
                                                <div class="font-meta-text font-xxxxs bold mb-xs">Arts &amp; Entertainment</div><a href="https://www.washingtonpost.com/entertainment/music/ddm-michael-k-williams-ballad-of-omar/2021/09/22/7a5cb9c0-1a1a-11ec-bcb8-0cb135811007_story.html" class="font-sm font--magazine-headline font-bold mb-sm black">How Michael K. Williams helped Baltimore rapper DDM find his voice</a>
                                            </div>
                                        </div>
                                        <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/entertainment/music/ddm-michael-k-williams-ballad-of-omar/2021/09/22/7a5cb9c0-1a1a-11ec-bcb8-0cb135811007_story.html" class="result-image-link">
                                                <figure data-qa="sc-figure-image" class="relative result-image-md" data-sc-v="6.5.3" data-sc-c="image">
                                                    <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 118.67px; height: 89px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                                </figure>
                                            </a></div>
                                    </div>
                                    <div class="mt-xs pa-xxxs pr-xxs flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-body mb-xxs font-light font-xxs antialiased gray-dark">Last year’s ‘The Ballad of Omar’ project takes on new resonance in the wake of the actor’s recent death.</div>
                                        </div>
                                        <div class="flex flex-column mt-sm">
                                            <div class="bold" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Lawrence Burney</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">September 23rd, 2021</span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="single-result mt-sm pb-sm">
                                <div class="content-lg pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light">
                                    <div class="pr-sm flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-meta-text font-xxxxs bold mb-xs">Transportation</div><a href="https://www.washingtonpost.com/transportation/2019/10/01/bowser-does-an-end-run-around-dc-council-transfers-speed-red-light-camera-program-ddot/" class="font-md font--magazine-headline font-bold mb-sm black">Bowser does an end run around D.C. Council, transfers traffic camera program to DDOT</a>
                                            <div class="font-body font-light font-xxs antialiased gray-dark lh-1 mt-sm mb-sm">The transfer, effective Tuesday, ramps up an ongoing political fight between the mayor and the D.C. Council over some of the city’s transportation priorities.</div>
                                        </div>
                                        <div class="flex items-center">
                                            <div class="bold mr-md" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Luz Lazo</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">October 02, 2019 at 4:13 AM GMT+5:30</span>
                                        </div>
                                    </div>
                                    <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/transportation/2019/10/01/bowser-does-an-end-run-around-dc-council-transfers-speed-red-light-camera-program-ddot/" class="result-image-link">
                                            <figure data-qa="sc-figure-image" class="relative result-image-lg" data-sc-v="6.5.3" data-sc-c="image">
                                                <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 240px; height: 180px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                            </figure>
                                        </a></div>
                                </div>
                                <div class="content-sm flex flex-column">
                                    <div class="pa-xxxs pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light ">
                                        <div class="pr-xs flex flex-column justify-between w-100">
                                            <div>
                                                <div class="font-meta-text font-xxxxs bold mb-xs">Transportation</div><a href="https://www.washingtonpost.com/transportation/2019/10/01/bowser-does-an-end-run-around-dc-council-transfers-speed-red-light-camera-program-ddot/" class="font-sm font--magazine-headline font-bold mb-sm black">Bowser does an end run around D.C. Council, transfers traffic camera program to DDOT</a>
                                            </div>
                                        </div>
                                        <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/transportation/2019/10/01/bowser-does-an-end-run-around-dc-council-transfers-speed-red-light-camera-program-ddot/" class="result-image-link">
                                                <figure data-qa="sc-figure-image" class="relative result-image-md" data-sc-v="6.5.3" data-sc-c="image">
                                                    <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 118.67px; height: 89px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                                </figure>
                                            </a></div>
                                    </div>
                                    <div class="mt-xs pa-xxxs pr-xxs flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-body mb-xxs font-light font-xxs antialiased gray-dark">The transfer, effective Tuesday, ramps up an ongoing political fight between the mayor and the D.C. Council over some of the city’s transportation priorities.</div>
                                        </div>
                                        <div class="flex flex-column mt-sm">
                                            <div class="bold" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Luz Lazo</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">October 2nd, 2019</span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="single-result mt-sm pb-sm">
                                <div class="content-lg pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light">
                                    <div class="pr-sm flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-meta-text font-xxxxs bold mb-xs">Opinions</div><a href="https://www.washingtonpost.com/opinions/ddots-arbitrary-decision-hurts-parking-in-neighborhoods/2019/08/27/bd964ce4-c817-11e9-9615-8f1a32962e04_story.html" class="font-md font--magazine-headline font-bold mb-sm black">Opinion | DDOT’s arbitrary decision hurts parking in neighborhoods</a>
                                            <div class="font-body font-light font-xxs antialiased gray-dark lh-1 mt-sm mb-sm"></div>
                                        </div>
                                        <div class="flex items-center">
                                            <div class="bold mr-md" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Letters to the Editor</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">August 29, 2019 at 2:42 AM GMT+5:30</span>
                                        </div>
                                    </div>
                                    <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/opinions/ddots-arbitrary-decision-hurts-parking-in-neighborhoods/2019/08/27/bd964ce4-c817-11e9-9615-8f1a32962e04_story.html" class="result-image-link">
                                            <figure data-qa="sc-figure-image" class="relative result-image-lg" data-sc-v="6.5.3" data-sc-c="image">
                                                <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 240px; height: 180px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                            </figure>
                                        </a></div>
                                </div>
                                <div class="content-sm flex flex-column">
                                    <div class="pa-xxxs pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light ">
                                        <div class="pr-xs flex flex-column justify-between w-100">
                                            <div>
                                                <div class="font-meta-text font-xxxxs bold mb-xs">Opinions</div><a href="https://www.washingtonpost.com/opinions/ddots-arbitrary-decision-hurts-parking-in-neighborhoods/2019/08/27/bd964ce4-c817-11e9-9615-8f1a32962e04_story.html" class="font-sm font--magazine-headline font-bold mb-sm black">Opinion | DDOT’s arbitrary decision hurts parking in neighborhoods</a>
                                            </div>
                                        </div>
                                        <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/opinions/ddots-arbitrary-decision-hurts-parking-in-neighborhoods/2019/08/27/bd964ce4-c817-11e9-9615-8f1a32962e04_story.html" class="result-image-link">
                                                <figure data-qa="sc-figure-image" class="relative result-image-md" data-sc-v="6.5.3" data-sc-c="image">
                                                    <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 118.67px; height: 89px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                                </figure>
                                            </a></div>
                                    </div>
                                    <div class="mt-xs pa-xxxs pr-xxs flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-body mb-xxs font-light font-xxs antialiased gray-dark"></div>
                                        </div>
                                        <div class="flex flex-column mt-sm">
                                            <div class="bold" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Letters to the Editor</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">August 29th, 2019</span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="single-result mt-sm pb-sm">
                                <div class="content-lg pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light">
                                    <div class="pr-sm flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-meta-text font-xxxxs bold mb-xs">Gridlock</div><a href="https://www.washingtonpost.com/news/dr-gridlock/wp/2018/07/30/dear-ddot-we-want-20000-dockless-bikes/" class="font-md font--magazine-headline font-bold mb-sm black">‘Dear DDOT’: We want 20,000 dockless bikes</a>
                                            <div class="font-body font-light font-xxs antialiased gray-dark lh-1 mt-sm mb-sm">Advocacy groups are petitioning the city to allow 20,000 dockless bikes.</div>
                                        </div>
                                        <div class="flex items-center">
                                            <div class="bold mr-md" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Luz Lazo</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">July 31, 2018 at 2:28 AM GMT+5:30</span>
                                        </div>
                                    </div>
                                    <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/news/dr-gridlock/wp/2018/07/30/dear-ddot-we-want-20000-dockless-bikes/" class="result-image-link">
                                            <figure data-qa="sc-figure-image" class="relative result-image-lg" data-sc-v="6.5.3" data-sc-c="image">
                                                <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 240px; height: 180px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                            </figure>
                                        </a></div>
                                </div>
                                <div class="content-sm flex flex-column">
                                    <div class="pa-xxxs pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light ">
                                        <div class="pr-xs flex flex-column justify-between w-100">
                                            <div>
                                                <div class="font-meta-text font-xxxxs bold mb-xs">Gridlock</div><a href="https://www.washingtonpost.com/news/dr-gridlock/wp/2018/07/30/dear-ddot-we-want-20000-dockless-bikes/" class="font-sm font--magazine-headline font-bold mb-sm black">‘Dear DDOT’: We want 20,000 dockless bikes</a>
                                            </div>
                                        </div>
                                        <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/news/dr-gridlock/wp/2018/07/30/dear-ddot-we-want-20000-dockless-bikes/" class="result-image-link">
                                                <figure data-qa="sc-figure-image" class="relative result-image-md" data-sc-v="6.5.3" data-sc-c="image">
                                                    <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 118.67px; height: 89px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                                </figure>
                                            </a></div>
                                    </div>
                                    <div class="mt-xs pa-xxxs pr-xxs flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-body mb-xxs font-light font-xxs antialiased gray-dark">Advocacy groups are petitioning the city to allow 20,000 dockless bikes.</div>
                                        </div>
                                        <div class="flex flex-column mt-sm">
                                            <div class="bold" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Luz Lazo</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">July 31st, 2018</span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="single-result mt-sm pb-sm">
                                <div class="content-lg pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light">
                                    <div class="pr-sm flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-meta-text font-xxxxs bold mb-xs">Gridlock</div><a href="https://www.washingtonpost.com/news/dr-gridlock/wp/2017/07/26/at-a-low-point-outgoing-transportation-chief-wondered-if-d-c-streetcar-would-ever-take-shape/" class="font-md font--magazine-headline font-bold mb-sm black">At ‘low point,’ outgoing DDOT chief wondered if D.C. Streetcar would ever take shape</a>
                                            <div class="font-body font-light font-xxs antialiased gray-dark lh-1 mt-sm mb-sm">Leif Dormsjo is set to join a private engineering firm to work on infrastructure issues.</div>
                                        </div>
                                        <div class="flex items-center">
                                            <div class="bold mr-md" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Martine Powers</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">July 27, 2017 at 3:08 AM GMT+5:30</span>
                                        </div>
                                    </div>
                                    <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/news/dr-gridlock/wp/2017/07/26/at-a-low-point-outgoing-transportation-chief-wondered-if-d-c-streetcar-would-ever-take-shape/" class="result-image-link">
                                            <figure data-qa="sc-figure-image" class="relative result-image-lg" data-sc-v="6.5.3" data-sc-c="image">
                                                <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 240px; height: 180px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                            </figure>
                                        </a></div>
                                </div>
                                <div class="content-sm flex flex-column">
                                    <div class="pa-xxxs pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light ">
                                        <div class="pr-xs flex flex-column justify-between w-100">
                                            <div>
                                                <div class="font-meta-text font-xxxxs bold mb-xs">Gridlock</div><a href="https://www.washingtonpost.com/news/dr-gridlock/wp/2017/07/26/at-a-low-point-outgoing-transportation-chief-wondered-if-d-c-streetcar-would-ever-take-shape/" class="font-sm font--magazine-headline font-bold mb-sm black">At ‘low point,’ outgoing DDOT chief wondered if D.C. Streetcar would ever take shape</a>
                                            </div>
                                        </div>
                                        <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/news/dr-gridlock/wp/2017/07/26/at-a-low-point-outgoing-transportation-chief-wondered-if-d-c-streetcar-would-ever-take-shape/" class="result-image-link">
                                                <figure data-qa="sc-figure-image" class="relative result-image-md" data-sc-v="6.5.3" data-sc-c="image">
                                                    <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 118.67px; height: 89px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                                </figure>
                                            </a></div>
                                    </div>
                                    <div class="mt-xs pa-xxxs pr-xxs flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-body mb-xxs font-light font-xxs antialiased gray-dark">Leif Dormsjo is set to join a private engineering firm to work on infrastructure issues.</div>
                                        </div>
                                        <div class="flex flex-column mt-sm">
                                            <div class="bold" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Martine Powers</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">July 27th, 2017</span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="single-result mt-sm pb-sm">
                                <div class="content-lg pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light">
                                    <div class="pr-sm flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-meta-text font-xxxxs bold mb-xs">Gridlock</div><a href="https://www.washingtonpost.com/news/dr-gridlock/wp/2016/08/18/what-does-ddot-do-with-all-those-twitter-complaints/" class="font-md font--magazine-headline font-bold mb-sm black">What does DDOT do with all those Twitter complaints?</a>
                                            <div class="font-body font-light font-xxs antialiased gray-dark lh-1 mt-sm mb-sm">People use Twitter to report everything from obstructed sidewalks and bike lanes to downed trees and dangerous intersections.</div>
                                        </div>
                                        <div class="flex items-center">
                                            <div class="bold mr-md" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Faiz Siddiqui</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">August 18, 2016 at 5:30 PM GMT+5:30</span>
                                        </div>
                                    </div>
                                    <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/news/dr-gridlock/wp/2016/08/18/what-does-ddot-do-with-all-those-twitter-complaints/" class="result-image-link">
                                            <figure data-qa="sc-figure-image" class="relative result-image-lg" data-sc-v="6.5.3" data-sc-c="image">
                                                <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 240px; height: 180px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                            </figure>
                                        </a></div>
                                </div>
                                <div class="content-sm flex flex-column">
                                    <div class="pa-xxxs pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light ">
                                        <div class="pr-xs flex flex-column justify-between w-100">
                                            <div>
                                                <div class="font-meta-text font-xxxxs bold mb-xs">Gridlock</div><a href="https://www.washingtonpost.com/news/dr-gridlock/wp/2016/08/18/what-does-ddot-do-with-all-those-twitter-complaints/" class="font-sm font--magazine-headline font-bold mb-sm black">What does DDOT do with all those Twitter complaints?</a>
                                            </div>
                                        </div>
                                        <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/news/dr-gridlock/wp/2016/08/18/what-does-ddot-do-with-all-those-twitter-complaints/" class="result-image-link">
                                                <figure data-qa="sc-figure-image" class="relative result-image-md" data-sc-v="6.5.3" data-sc-c="image">
                                                    <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 118.67px; height: 89px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                                </figure>
                                            </a></div>
                                    </div>
                                    <div class="mt-xs pa-xxxs pr-xxs flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-body mb-xxs font-light font-xxs antialiased gray-dark">People use Twitter to report everything from obstructed sidewalks and bike lanes to downed trees and dangerous intersections.</div>
                                        </div>
                                        <div class="flex flex-column mt-sm">
                                            <div class="bold" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Faiz Siddiqui</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">August 18th, 2016</span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="single-result mt-sm pb-sm">
                                <div class="content-lg pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light">
                                    <div class="pr-sm flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-meta-text font-xxxxs bold mb-xs">Arts &amp; Entertainment</div><a href="https://www.washingtonpost.com/news/arts-and-entertainment/wp/2014/05/20/the-voice-star-ddendyl-on-life-after-reality-tv-and-how-the-nbc-singing-show-really-operates/" class="font-md font--magazine-headline font-bold mb-sm black">‘The Voice’ star Ddendyl on life after reality TV, and how the NBC singing show really operates</a>
                                            <div class="font-body font-light font-xxs antialiased gray-dark lh-1 mt-sm mb-sm">How much time do the celebrity coaches/judges really spend with their team members?</div>
                                        </div>
                                        <div class="flex items-center">
                                            <div class="bold mr-md" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Emily Yahr</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">May 26, 2016 at 8:36 PM GMT+5:30</span>
                                        </div>
                                    </div>
                                    <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/news/arts-and-entertainment/wp/2014/05/20/the-voice-star-ddendyl-on-life-after-reality-tv-and-how-the-nbc-singing-show-really-operates/" class="result-image-link">
                                            <figure data-qa="sc-figure-image" class="relative result-image-lg" data-sc-v="6.5.3" data-sc-c="image">
                                                <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 240px; height: 180px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                            </figure>
                                        </a></div>
                                </div>
                                <div class="content-sm flex flex-column">
                                    <div class="pa-xxxs pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light ">
                                        <div class="pr-xs flex flex-column justify-between w-100">
                                            <div>
                                                <div class="font-meta-text font-xxxxs bold mb-xs">Arts &amp; Entertainment</div><a href="https://www.washingtonpost.com/news/arts-and-entertainment/wp/2014/05/20/the-voice-star-ddendyl-on-life-after-reality-tv-and-how-the-nbc-singing-show-really-operates/" class="font-sm font--magazine-headline font-bold mb-sm black">‘The Voice’ star Ddendyl on life after reality TV, and how the NBC singing show really operates</a>
                                            </div>
                                        </div>
                                        <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/news/arts-and-entertainment/wp/2014/05/20/the-voice-star-ddendyl-on-life-after-reality-tv-and-how-the-nbc-singing-show-really-operates/" class="result-image-link">
                                                <figure data-qa="sc-figure-image" class="relative result-image-md" data-sc-v="6.5.3" data-sc-c="image">
                                                    <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 118.67px; height: 89px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                                </figure>
                                            </a></div>
                                    </div>
                                    <div class="mt-xs pa-xxxs pr-xxs flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-body mb-xxs font-light font-xxs antialiased gray-dark">How much time do the celebrity coaches/judges really spend with their team members?</div>
                                        </div>
                                        <div class="flex flex-column mt-sm">
                                            <div class="bold" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Emily Yahr</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">May 26th, 2016</span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="single-result mt-sm pb-sm">
                                <div class="content-lg pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light">
                                    <div class="pr-sm flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-meta-text font-xxxxs bold mb-xs">D.C., Md. &amp; Va.</div><a href="https://www.washingtonpost.com/local/trafficandcommuting/ddot-official-pleads-not-guilty-after-ig-cites-170000-in-undisclosed-income/2016/05/17/91586842-1c5d-11e6-9c81-4be1c14fb8c8_story.html" class="font-md font--magazine-headline font-bold mb-sm black">DDOT official pleads not guilty after IG cites $170,000 in undisclosed income</a>
                                            <div class="font-body font-light font-xxs antialiased gray-dark lh-1 mt-sm mb-sm">A lawyer for the senior civil engineer says his client got the forms wrong.</div>
                                        </div>
                                        <div class="flex items-center">
                                            <div class="bold mr-md" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none"> Michael Laris Michael Laris</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">May 18, 2016 at 3:18 AM GMT+5:30</span>
                                        </div>
                                    </div>
                                    <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/local/trafficandcommuting/ddot-official-pleads-not-guilty-after-ig-cites-170000-in-undisclosed-income/2016/05/17/91586842-1c5d-11e6-9c81-4be1c14fb8c8_story.html" class="result-image-link">
                                            <figure data-qa="sc-figure-image" class="relative result-image-lg" data-sc-v="6.5.3" data-sc-c="image">
                                                <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 240px; height: 180px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                            </figure>
                                        </a></div>
                                </div>
                                <div class="content-sm flex flex-column">
                                    <div class="pa-xxxs pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light ">
                                        <div class="pr-xs flex flex-column justify-between w-100">
                                            <div>
                                                <div class="font-meta-text font-xxxxs bold mb-xs">D.C., Md. &amp; Va.</div><a href="https://www.washingtonpost.com/local/trafficandcommuting/ddot-official-pleads-not-guilty-after-ig-cites-170000-in-undisclosed-income/2016/05/17/91586842-1c5d-11e6-9c81-4be1c14fb8c8_story.html" class="font-sm font--magazine-headline font-bold mb-sm black">DDOT official pleads not guilty after IG cites $170,000 in undisclosed income</a>
                                            </div>
                                        </div>
                                        <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/local/trafficandcommuting/ddot-official-pleads-not-guilty-after-ig-cites-170000-in-undisclosed-income/2016/05/17/91586842-1c5d-11e6-9c81-4be1c14fb8c8_story.html" class="result-image-link">
                                                <figure data-qa="sc-figure-image" class="relative result-image-md" data-sc-v="6.5.3" data-sc-c="image">
                                                    <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 118.67px; height: 89px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                                </figure>
                                            </a></div>
                                    </div>
                                    <div class="mt-xs pa-xxxs pr-xxs flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-body mb-xxs font-light font-xxs antialiased gray-dark">A lawyer for the senior civil engineer says his client got the forms wrong.</div>
                                        </div>
                                        <div class="flex flex-column mt-sm">
                                            <div class="bold" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none"> Michael Laris Michael Laris</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">May 18th, 2016</span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="single-result mt-sm pb-sm">
                                <div class="content-lg pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light">
                                    <div class="pr-sm flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-meta-text font-xxxxs bold mb-xs">Animals</div><a href="https://www.washingtonpost.com/news/animalia/wp/2016/04/06/chesapeake-bay-osprey-are-having-a-huge-rebound-after-the-removal-of-this-deadly-poison/" class="font-md font--magazine-headline font-bold mb-sm black">DDT nearly wiped out these birds. Now they’re making a comeback</a>
                                            <div class="font-body font-light font-xxs antialiased gray-dark lh-1 mt-sm mb-sm">After DDT nearly wiped them out in the 1970s, fish hawks are back in the Chesapeake Bay in a big way.</div>
                                        </div>
                                        <div class="flex items-center">
                                            <div class="bold mr-md" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Darryl Fears</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">April 06, 2016 at 8:30 PM GMT+5:30</span>
                                        </div>
                                    </div>
                                    <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/news/animalia/wp/2016/04/06/chesapeake-bay-osprey-are-having-a-huge-rebound-after-the-removal-of-this-deadly-poison/" class="result-image-link">
                                            <figure data-qa="sc-figure-image" class="relative result-image-lg" data-sc-v="6.5.3" data-sc-c="image">
                                                <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 240px; height: 180px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                            </figure>
                                        </a></div>
                                </div>
                                <div class="content-sm flex flex-column">
                                    <div class="pa-xxxs pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light ">
                                        <div class="pr-xs flex flex-column justify-between w-100">
                                            <div>
                                                <div class="font-meta-text font-xxxxs bold mb-xs">Animals</div><a href="https://www.washingtonpost.com/news/animalia/wp/2016/04/06/chesapeake-bay-osprey-are-having-a-huge-rebound-after-the-removal-of-this-deadly-poison/" class="font-sm font--magazine-headline font-bold mb-sm black">DDT nearly wiped out these birds. Now they’re making a comeback</a>
                                            </div>
                                        </div>
                                        <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/news/animalia/wp/2016/04/06/chesapeake-bay-osprey-are-having-a-huge-rebound-after-the-removal-of-this-deadly-poison/" class="result-image-link">
                                                <figure data-qa="sc-figure-image" class="relative result-image-md" data-sc-v="6.5.3" data-sc-c="image">
                                                    <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 118.67px; height: 89px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                                </figure>
                                            </a></div>
                                    </div>
                                    <div class="mt-xs pa-xxxs pr-xxs flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-body mb-xxs font-light font-xxs antialiased gray-dark">After DDT nearly wiped them out in the 1970s, fish hawks are back in the Chesapeake Bay in a big way.</div>
                                        </div>
                                        <div class="flex flex-column mt-sm">
                                            <div class="bold" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Darryl Fears</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">April 6th, 2016</span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="single-result mt-sm pb-sm">
                                <div class="content-lg pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light">
                                    <div class="pr-sm flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-meta-text font-xxxxs bold mb-xs">D.C., Md. &amp; Va.</div><a href="https://www.washingtonpost.com/blogs/all-opinions-are-local/wp/2015/07/20/is-ddot-using-incomplete-information-for-traffic-safety-analysis/" class="font-md font--magazine-headline font-bold mb-sm black">Is DDOT using incomplete information for traffic safety analysis?</a>
                                            <div class="font-body font-light font-xxs antialiased gray-dark lh-1 mt-sm mb-sm"></div>
                                        </div>
                                        <div class="flex items-center">
                                            <div class="bold mr-md" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Drew Schneider</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">July 21, 2015 at 3:11 AM GMT+5:30</span>
                                        </div>
                                    </div>
                                    <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/blogs/all-opinions-are-local/wp/2015/07/20/is-ddot-using-incomplete-information-for-traffic-safety-analysis/" class="result-image-link">
                                            <figure data-qa="sc-figure-image" class="relative result-image-lg" data-sc-v="6.5.3" data-sc-c="image">
                                                <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 240px; height: 180px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                            </figure>
                                        </a></div>
                                </div>
                                <div class="content-sm flex flex-column">
                                    <div class="pa-xxxs pt-xxxs pb-xxxs antialiased flex align-items bc-gray-light ">
                                        <div class="pr-xs flex flex-column justify-between w-100">
                                            <div>
                                                <div class="font-meta-text font-xxxxs bold mb-xs">D.C., Md. &amp; Va.</div><a href="https://www.washingtonpost.com/blogs/all-opinions-are-local/wp/2015/07/20/is-ddot-using-incomplete-information-for-traffic-safety-analysis/" class="font-sm font--magazine-headline font-bold mb-sm black">Is DDOT using incomplete information for traffic safety analysis?</a>
                                            </div>
                                        </div>
                                        <div class="h-100 flex flex-column self-center"><a href="https://www.washingtonpost.com/blogs/all-opinions-are-local/wp/2015/07/20/is-ddot-using-incomplete-information-for-traffic-safety-analysis/" class="result-image-link">
                                                <figure data-qa="sc-figure-image" class="relative result-image-md" data-sc-v="6.5.3" data-sc-c="image">
                                                    <div data-qa="sc-skeleton-image" class="font-0 bg-gray-lighter" data-sc-v="6.5.3" data-sc-c="fadeinimage" style="width: 118.67px; height: 89px; opacity: 0; transition: opacity 400ms ease-in-out 0s;"></div>
                                                </figure>
                                            </a></div>
                                    </div>
                                    <div class="mt-xs pa-xxxs pr-xxs flex flex-column justify-between w-100">
                                        <div>
                                            <div class="font-body mb-xxs font-light font-xxs antialiased gray-dark"></div>
                                        </div>
                                        <div class="flex flex-column mt-sm">
                                            <div class="bold" data-sc-v="6.5.3" data-sc-c="author"><span data-sc-v="6.5.3" data-sc-c="author">
                                                    <div class="mb-xxs flex items-center" data-sc-v="6.5.3" data-sc-c="authors"><span class="" data-sc-v="6.5.3" data-sc-c="authors">
                                                            <div class="flex" data-sc-v="6.5.3" data-sc-c="nameanddelimiter">
                                                                <div class="font-xxxs dib font-xxs-ns" data-qa="name-with-optional-link" data-cy="name-with-optional-link" data-sc-v="6.5.3" data-sc-c="namewithoptionallink"><span class="gray-darkest" data-qa="attribution-text" data-sc-v="6.5.3" data-sc-c="namewithoptionallink">By </span><span data-qa="author-name" class="gray-darkest b bb bc-gray bt-hover b-none">Drew Schneider</span></div>
                                                            </div>
                                                        </span></div>
                                                </span></div><span class="flex nowrap font-light gray-dark italic font-xxxxs">July 21st, 2015</span>
                                        </div>
                                    </div>
                                </div>
                            </article><button class="inline-flex items-center justify-center lh-md overflow-hidden border-box min-w-btn transition-colors duration-200 ease-in-out font-sans-serif font-bold antialiased bg-offblack hover-bg-gray-darker focus-bg-gray-darker white b-solid bw bc-transparent focus-bc-black mt-md mb-md brad-lg pl-md pr-md h-md pt-0 pb-0 w-100 pointer">Load more results</button>
                        </section>
                        <section></section>
                    </div>
                    <div data-qa="right-rail-ad" class="dn db-ns mt-0 mb-0 pb-lg-mod mr-auto ml-auto hide-for-print flex flex-column right-rail flex-1 col-4-lg flex-l">
                        <div aria-hidden="true" class="hide-for-print relative flex justify-center content-box items-center b bh" style="border-top-color:transparent;border-bottom-color:transparent">
                            <div class="center absolute w-100 border-box" data-sc-v="6.5.3" data-sc-c="adslot">
                                <div class="dib gray-dark pl-xs pr-xs font-sans-serif light font-xxxxs lh-md" style="--primary-border-color:" data-sc-v="6.5.3" data-sc-c="adslot"></div>
                            </div>
                            <div class="relative flex flex-column justify-center w-100" data-sc-v="6.5.3" data-sc-c="adslot" style="min-height: 600px; min-width: 300px;">
                                <!-- <wp-ad id="slug_flex_ss_bb_hp" class="chromatic-ignore" data-chromatic="ignore" data-sc-v="6.5.3" data-sc-c="adslot" data-slot="/701/wpni.politics/front" aria-hidden="true" data-renderbehavior="lazy" data-refresh="true" data-json="{&quot;targeting&quot;:{&quot;zeus_rendercount&quot;:&quot;3&quot;,&quot;zeus_slot&quot;:&quot;slug_flex_ss_bb_hp.ref.dsk&quot;,&quot;pos&quot;:&quot;flex_ss_bb_hp&quot;,&quot;ctr&quot;:[&quot;zeus_flex_ss_bb_hp_refresh&quot;,&quot;refresh&quot;],&quot;wp_ad_refresh&quot;:&quot;1&quot;,&quot;wp_refresh&quot;:&quot;flex_ss_bb_hp_2&quot;,&quot;pwt&quot;:[&quot;flex_ss_bb_hp_refresh_nav_0&quot;]}}" style="min-height: 600px; margin: auto; width: 221px; display: block;" data-google-query-id="CODC0I_tqfYCFcOGrAIdGmEE8g">
                                    <div id="google_ads_iframe_/701/wpni.politics/front_1__container__" style="border: 0pt none;"><iframe id="google_ads_iframe_/701/wpni.politics/front_1" name="google_ads_iframe_/701/wpni.politics/front_1" title="3rd party ad content" width="221" height="828" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" role="region" aria-label="Advertisement" tabindex="0" sandbox="allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation-by-user-activation" srcdoc="" style="border: 0px; vertical-align: bottom;" data-google-container-id="2" data-load-complete="true"></iframe></div>
                                </wp-ad> -->
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    </div>
</body>
</template>

<script>
import axios from "axios";
import PriceRangeSlider from "@/components/PriceRangeSlider";
import Header from "@/components/Header";
import Footer from "@/components/footer";
export default {
    components: {
        Header,
        Footer,
        PriceRangeSlider,
    },
    data() {
        return {
            isFilter: false,
            selectCat: 0,
            page: 2,
            isAct: 0,
            search_key: "",
            ProductsCount: 0,
            isHas: false,
            filterData: false,
            productCount: 0,
            range: {
                minValue: 0,
                maxValue: 1000,
            },
            ProdNoRecordFound: false,
            NoRecordFound: false,
            limit: 0,
            startval: null,
            isCheckeds: false,
            colorVal: [],
            colors: 0,
            setSelected: 0,
            sizes: 0,
            allCategory: [],
            allProducts: [],
            id: this.$route.params.id,
        };
    },
    mounted() {
        this.searchingVar = JSON.parse(localStorage.getItem("searchingVar"));
        this.goToTop();
        this.categories();
        this.catData(this.$route.params.id);
    },
    watch: {
        "$route.params.id": function (newVal, oldVal) {
            this.catData(newVal);
        },
        selectCat: function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.isFilter = true;
                this.catData();
            }
        },
        search_key: function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.isFilter = true;
                // this.catData();
            }
        },
        range: function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.isFilter = true;
                // this.catData();
            }
        },
    },
    methods: {
        selectCategory(id) {
            this.selectCat = id;
        },
        limitSet(id) {
            this.limit = this.limit + id;
            this.isAct = id;
            this.catData();
        },
        w3_open() {
            document.getElementById("mySidebar").style.display = "block";
        },

        w3_close() {
            document.getElementById("mySidebar").style.display = "none";
        },
        search(id) {
            var self = this;
            if (self.search_key == "") {
                self.$toasted.global.error({
                    message: "Please and product or category name",
                });
            } else {
                axios
                    .post(this.$root.URL_ROOT + "api.php", {
                        type: "searchProduct",
                        cid: self.id,
                        search_key: self.search_key,
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            self.allProducts = data.data;
                            self.ProductsCount = data.count;
                        } else {
                            self.NoRecordFound = true;
                            self.ProductsCount = data.count;
                        }
                    });
            }
        },
        categories() {
            var ev = this;
            axios
                .post(this.$root.URL_ROOT + "api.php", {
                    type: "getCategory",
                })
                .then(function (response) {
                    var data = response.data;
                    if (data.status == "success") {
                        ev.allCategory = data.category;
                    }
                });
        },
        catData(id) {
            this.id = id;
            const axios = require("axios");
            var self = this;
            if (this.isFilter != false) {
                axios
                    .post(this.$root.URL_ROOT + "api.php", {
                        type: "priceSearchProduct",
                        min: self.range.minValue,
                        max: self.range.maxValue,
                        cid: self.selectCat,
                        search_key: self.search_key,
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            self.allProducts = data.data;
                            self.ProductsCount = data.count;
                            self.searchshow = true;
                        } else {
                            self.NoRecordFound = true;
                            self.ProductsCount = data.count;
                        }
                    });
            } else {
                axios
                    .post(this.$root.URL_ROOT + "api.php", {
                        type: "categoryProduct",
                        id: id,
                        limit: this.limit,
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            self.allProducts = data.product;
                            self.ProductsCount = data.count;
                            self.productCount = parseInt(self.allProducts.count);
                        } else {}
                    });
            }
        },
        goToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
    },
};
</script>

<style scoped>
.search-results-wrapper .single-result {
    border-bottom: 1px solid;
    border-color: var(--color-ui-gray-light)
}

.search-results-wrapper .content-lg {
    display: flex
}

.search-results-wrapper .content-sm {
    display: none
}

@media only screen and (max-width:768px) {
    .search-results-wrapper .content-lg {
        display: none
    }

    .search-results-wrapper .content-sm {
        display: flex;
        height: fit-content
    }
}

input {
    box-shadow: 0;
    outline: 0;
}

.price-slider {
    width: 300px;
    margin: 20px 0;
    text-align: center;
    position: relative;
    height: 9em;
    display: flex;
    flex-direction: column;
}

.price-slider>span {
    display: block;
    margin-bottom: 24px;
}

.price-slider>div {
    position: relative;
    margin-top: 10px;
}

input.start {
    bottom: 10px;
}

input.end {
    top: 20px;
}

.price-slider svg,
.price-slider input[type="range"] {
    position: absolute;
    left: 0;
}

input[type="number"] {
    border: 1px solid #ddd;
    text-align: center;
    font-size: 1.6em;
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"]:invalid,
input[type="number"]:out-of-range {
    border: 2px solid #e60023;
}

input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #1da1f2;
}

input[type="range"]:focus::-ms-fill-lower {
    background: #1da1f2;
}

input[type="range"]:focus::-ms-fill-upper {
    background: #1da1f2;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: #1da1f2;
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

input[type="range"]::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid #1da1f2;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: #1da1f2;
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

input[type="range"]::-moz-range-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid #1da1f2;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
}

input[type="range"]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
    background: #1da1f2;
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

input[type="range"]::-ms-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid #1da1f2;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
}

#mySidebar {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: #fff;
    overflow: auto;
}

#mySidebar .panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9;
}

.color-div {
    width: 35px;
    height: 35px;
    border: 1px solid #d6d6d6;
}

.allp {
    display: block !important;
}

.filp {
    display: block !important;
}

.hidethis {
    display: none;
}

.checkstyle {
    margin: 20px 0 0 20px;
    visibility: visible;
}

.product-list .product-block .desc {
    height: 150px;
    overflow: hidden;
}

.size-css {
    margin: 0 50px 0 0 !important;
}

.bclass {
    border: 1px solid #000;
}

.checkbox input[type="checkbox"] {
    float: right;
    margin: 15px -30px 0 0;
    visibility: hidden;
}

.checkeds {
    background: #000 !important;
    color: #fff !important;
}

button>label>input[type="checkbox"]:checked {
    background: #000 !important;
    color: #fff !important;
}

.bclass {
    border: 1px solid #000;
}

.boxes {
    margin: auto;
    padding: 50px;
    background: #484848;
}

/*Checkboxes styles*/
input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]+label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    font: 14px/20px "Open Sans", Arial, sans-serif;
    color: #ddd;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

input[type="checkbox"]+label:last-child {
    margin-bottom: 0;
}

input[type="checkbox"]+label:before {
    content: "";
    display: block;
    width: 35px;
    height: 35px;
    border: 2px solid #ffffff;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.6;
    -webkit-transition: all 0.12s, border-color 0.08s;
    transition: all 0.12s, border-color 0.08s;
}

input[type="checkbox"]:checked+label:before {
    width: 10px;
    top: -5px;
    left: 20px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.app-content {
    padding: 40px 15px;
}

.radio,
.checkbox {
    display: inline-flex;
    min-height: 20px;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.c-label {
    color: #fff !important;
    padding: 12px;
    text-transform: capitalize;
    font-weight: bold !important;
}

.c-labels {
    color: #000 !important;
    padding: 12px;
    text-transform: capitalize;
    font-weight: bold !important;
}

.c-labels:hover {
    background: #000 !important;
    color: #fff !important;
}

input[type="checkbox"]+.c-labels:before {
    content: "";
    display: block;
    width: 70px;
    height: 45px;
    border: 2px solid #adadad;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.6;
    transition: all 0.12s, border-color 0.08s;
}

.searchshow {
    display: block !important;
}

.price-filter {
    margin: 20px 0;
}

.price-filter button {
    text-align: right;
}

.pagination {
    display: inline-block;
    float: right;
}

.pagination a {
    color: black;
    float: left;
    padding: 4px 5px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
}

.pagination a.active {
    background-color: #b6262f;
    color: white;
    border: 1px solid #b6262f;
}

.pagination a:hover:not(.active) {
    background-color: #ddd;
}

#breadcrumb_wrapper {
    float: right;
    text-align: right;
    padding: 0;
    margin: 0 0 20px 0;
    right: 0;
    width: 74%;
    position: relative;
}

#search_filters {
    margin: 20px 0;
    border: 5px solid #f1f1f1;
    border-radius: 10px;
}

#search_filters .filter-heading {
    background: #f1f1f1;
    padding: 15px;
}

a.product-category.active {
    color: #fdb306;
}

@media only screen and (max-width: 600px) {
    .mobile-margin {
        margin-top: 30px;
    }
}
</style>
